import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import AppContext from 'context/Context';
import { Card, Row, Col } from 'react-bootstrap';
import { portfolioList } from 'pages/CreateBot/staticData/portfolioList';
import { Link } from 'react-router-dom';
import { BotDashboard as textLang } from 'staticData/languages';
import LottieBotLazy from 'services/lazyLoading/LottieBotLazy';
import IconButton from 'components/common/IconButton';
import Flex from 'components/common/Flex';
import classNames from 'classnames';
import { walletImgRender } from 'services/coins/components';
import ButtonGrid from 'pages/CreateBot/ButtonGrid';

const NoPortfolio = ({ isPending = false, portfolioData = {} }) => {
  const {
    config: { lang },
    userInfos
  } = useContext(AppContext);

  const [selectPortfolio, setSelectPortfolio] = useState();

  useEffect(() => {
    const initData = async () => {
      try {
        if (
          isPending &&
          Object.keys(portfolioData) &&
          portfolioData.bot_details?.type
        ) {
          const tmpPortfolio = portfolioList.find(
            portfolio =>
              String(portfolio.id) === String(portfolioData.bot_details.type)
          );
          setSelectPortfolio(tmpPortfolio);
        } else {
          const bannerRandomID = Math.floor(Math.random() * 3);
          setSelectPortfolio(portfolioList[bannerRandomID]);
        }
      } catch (error) {
        console.error(error.message);
      }
    };
    if (Object.keys(userInfos).length > 0 && !selectPortfolio) initData();
  }, [userInfos]);

  return (
    <>
      <Row className={classNames('', { pointerCursor: !isPending })}>
        <Col>
          <Card
            style={{
              backgroundImage: selectPortfolio ? selectPortfolio.bgStyle : ''
            }}
          >
            <Card.Body
              as={!isPending ? Link : 'div'}
              to={
                !isPending
                  ? '/invest?exchange=binance&type=portfolio'
                  : undefined
              }
              style={
                !isPending
                  ? { textDecoration: 'none', color: 'inherit' }
                  : undefined
              }
            >
              <Row>
                <Col sm={5}>
                  {selectPortfolio && (
                    <LottieBotLazy
                      type={selectPortfolio.id}
                      loop={true}
                      style={{ width: '100%' }}
                    />
                  )}
                </Col>
                <Col md={6} as={Flex} className="align-items-center">
                  <div>
                    <h4>
                      {!isPending
                        ? textLang.noBotTitle[lang]
                        : selectPortfolio &&
                          textLang.isPendingTitle[lang] +
                            selectPortfolio.name +
                            textLang.isPendingTitle_2[lang]}
                    </h4>
                    {isPending && selectPortfolio && (
                      <>
                        <Flex className="align-items-center mb-2">
                          <h6 className="mb-0 me-2">
                            {textLang.riskLevel[lang]}
                          </h6>
                          <ButtonGrid
                            actives={[
                              selectPortfolio.riskLevels[
                                selectPortfolio.backendMinLevels.indexOf(
                                  portfolioData.bot_details.min_level
                                )
                              ]
                            ]}
                            click={false}
                            buttonSize={'sm'}
                          />
                        </Flex>
                        <Flex justifyContent="start" className="mb-3">
                          {walletImgRender(
                            selectPortfolio.coins,
                            '',
                            true,
                            'm'
                          )}
                        </Flex>
                      </>
                    )}
                    <p>
                      {!isPending
                        ? textLang.noBotText[lang]
                        : textLang.isPendingText[lang]}
                    </p>
                    {!isPending && (
                      <IconButton
                        variant="primary"
                        className="me-2 mb-1 "
                        icon="rocket"
                        iconAlign="left"
                        transform="shrink-3"
                      >
                        {textLang.noBotButton[lang]}
                      </IconButton>
                    )}
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

NoPortfolio.propTypes = {
  isPending: PropTypes.bool,
  portfolioData: PropTypes.object
};

export default NoPortfolio;
