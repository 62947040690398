import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Tooltip, OverlayTrigger } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { Rewards as textLang } from 'staticData/languages';
import AppContext from 'context/Context';
import IconButton from 'components/common/IconButton';
import SoftBadge from 'components/common/SoftBadge';
import { createShareBody } from 'pages/Rewards';
import { trackEvent } from 'services/externals/mixpanel';
import { saveToLocalStorage, getFromLocalStorage } from 'staticData/common';
import { ShareReferralOnSocial as shareTextLang } from 'staticData/languages';

const ReferralModal = ({ showModal, setShowModal }) => {
  const {
    config: { lang },
    userInfos
  } = useContext(AppContext);
  const referral = userInfos.my_referral;
  var defaultTooltipText = textLang.copy[lang];
  const [tooltipText, setTooltipText] = useState(defaultTooltipText);

  const handleTrackEvent = type => {
    trackEvent('Share', {
      type: type
    });
  };

  const copyReferral = (useLink = false) => {
    handleTrackEvent('manual copy');
    navigator.clipboard.writeText(
      useLink ? shareTextLang.shareUrl + referral : referral
    );
    setTooltipText(textLang.copied[lang]);
    setTimeout(() => {
      setTooltipText(defaultTooltipText);
    }, 1500);
  };

  const handleClose = () => {
    trackEvent('Referral Modal Close', {});
    setShowModal(false);
    const hideModalreferralUntill = getFromLocalStorage(
      'hideModalreferralUntill'
    );
    const hideTimes = hideModalreferralUntill?.hideTimes
      ? hideModalreferralUntill.hideTimes + 1
      : 1;
    const newDate = new Date();
    newDate.setDate(newDate.getDate() + (hideTimes > 2 ? 15 : 5));
    saveToLocalStorage('hideModalreferralUntill', {
      ts: newDate,
      hideTimes: hideTimes
    });
  };

  useEffect(() => {
    if (showModal) trackEvent('Referral Modal Show', {});
  }, [showModal]);

  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title id="contained-modal-title-vcenter">
          <div
            dangerouslySetInnerHTML={{
              __html: textLang.dashModalTitle[lang]
            }}
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          dangerouslySetInnerHTML={{ __html: textLang.dashModalText[lang] }}
        />
        <Flex justifyContent="center" className="p-0 mt-5">
          <span className="d-flex text-center p-0 m-0">
            <h4 className="text-900" style={{ margin: '3px' }}>
              {textLang.inviteAFriend[lang]}
            </h4>
          </span>
        </Flex>
        <Flex className="mb-2 mt-1" justifyContent="center">
          <SoftBadge
            className=" d-flex align-items-center justify-content-center"
            bg="info"
          >
            <p className="m-0 p-0" style={{ fontSize: '20px' }}>
              {referral}
            </p>
          </SoftBadge>
          <OverlayTrigger
            key="copy"
            placement="top"
            overlay={<Tooltip id="copyReferral">{tooltipText}</Tooltip>}
          >
            <div style={{ marginLeft: '5px', width: 'fit-content' }}>
              <IconButton
                style={{ padding: '0 0.35rem' }}
                variant="outline-info"
                icon="copy"
                onClick={() => copyReferral(false)}
              ></IconButton>
            </div>
          </OverlayTrigger>
          <OverlayTrigger
            key="copy"
            placement="top"
            overlay={<Tooltip id="copyReferral">{tooltipText}</Tooltip>}
          >
            <div style={{ marginLeft: '5px', width: 'fit-content' }}>
              <IconButton
                style={{ padding: '0 0.35rem' }}
                variant="outline-info"
                icon="link"
                onClick={() => copyReferral(true)}
              ></IconButton>
            </div>
          </OverlayTrigger>
        </Flex>
        {createShareBody(lang, referral, false)}
      </Modal.Body>
    </Modal>
  );
};

ReferralModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  head: PropTypes.object,
  body: PropTypes.object,
  button: PropTypes.object
};

export default ReferralModal;
