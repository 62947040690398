import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Modal, Row, Col } from 'react-bootstrap';
import { useHistory, Link } from 'react-router-dom';
import PortfolioCol from 'pages/CreateBot/common/PortfolioCol';
import AppContext from 'context/Context';
import PortfolioWallet from 'pages/CreateBot/Portfolio/PortfolioWallet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Done from 'pages/CreateBot/Done';
import ButtonGrid from 'pages/CreateBot/ButtonGrid';
import { portfolioList } from 'pages/CreateBot/staticData/portfolioList';
import { breakpoints } from 'helpers/utils';
import { CreatePortfolioModal as textLang } from 'staticData/languages';

const CreatePortfolioModal = ({ showModal, setShowModal }) => {
  const {
    config: { lang },
    userInfos
  } = useContext(AppContext);
  const [showWallet, setShowWallet] = useState(false);
  const [riskLevelsSelect, setRiskLevelsSelect] = useState();
  const [selectedID, setSelectedID] = useState();
  const routerHistory = useHistory();
  const [createdBot, setCreatedBot] = useState();

  const maxFirstProTierTotalAllocation = userInfos.permissions?.max_real_capital
    ? userInfos.permissions.max_real_capital
    : 10000;

  const overlayWrapperStyle = {
    position: 'relative',
    margin: '10px'
  };

  const portfolioOverlayStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'transparent',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    color: 'white'
  };

  const bottomLeftTextStyle = {
    position: 'absolute',
    bottom: '10px',
    left: '20px'
  };

  const handleSelectRisk = risk => {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    params.set('risk', risk);
    params.set('wallet', 'show');
    setShowWallet(true);
    routerHistory.push(`?${params.toString()}`);
  };

  const handleCloseModal = () => {
    if (createdBot) window.location.href = '/';
    setShowModal(false);
    routerHistory.push('/');
    setShowWallet(false);
    setRiskLevelsSelect();
    setSelectedID();
  };

  useEffect(() => {
    if (showModal) {
      const url = new URL(window.location.href);
      const params = new URLSearchParams(url.search);
      const id = params.get('id');
      const showWalletParam = params.get('wallet');
      if (id) {
        if (id !== selectedID) setSelectedID(id);
        const { riskLevels } = portfolioList.find(
          portfolio => portfolio.id === id
        );
        setRiskLevelsSelect(riskLevels);
      }
      if (!showWalletParam) setShowWallet(false);
    }
  }, [window.location.search]);

  return (
    <Modal
      show={showModal}
      onHide={handleCloseModal}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="ps-0"
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title id="contained-modal-title-vcenter">
          {createdBot ? '' : textLang.title[lang]}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {showWallet ? (
          createdBot ? (
            <Done
              createdBot={createdBot}
              setCreatedBot={setCreatedBot}
              isPortfolio={true}
            />
          ) : (
            <PortfolioWallet
              id={selectedID}
              setCreatedBot={setCreatedBot}
              maxFirstProTierTotalAllocation={maxFirstProTierTotalAllocation}
              setShowExternalModal={setShowModal}
            />
          )
        ) : (
          <>
            <h6
              className="mb-3"
              dangerouslySetInnerHTML={{ __html: textLang.subTitle[lang] }}
            />
            <Row className={'pt-3'}>
              {portfolioList.map((portfolio, index) => (
                <Col style={overlayWrapperStyle} key={index}>
                  <div
                    style={{ opacity: selectedID === portfolio.id ? 0.2 : 1 }}
                  >
                    <PortfolioCol portfolio={portfolio} />
                  </div>
                  {selectedID === portfolio.id && riskLevelsSelect && (
                    <div style={portfolioOverlayStyle}>
                      <h5 className="text-center mb-3">
                        {textLang.riskLevelSelect[lang]}
                      </h5>
                      <ButtonGrid
                        {...(window.innerWidth < breakpoints['sm'] && {
                          actives: riskLevelsSelect
                        })}
                        colored={riskLevelsSelect}
                        click={true}
                        hover={true}
                        buttonSize={'md'}
                        action={handleSelectRisk}
                        showCaret={false}
                        className="mt-3 mt-sm-0"
                      />
                      <Link
                        to={`/invest${window.location.search}`}
                        className="mt-3"
                        style={bottomLeftTextStyle}
                      >
                        <FontAwesomeIcon icon={'chart-line'} className="me-2" />
                        <small>{textLang.portfolioDetails[lang]}</small>
                      </Link>
                    </div>
                  )}
                </Col>
              ))}
            </Row>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

CreatePortfolioModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  head: PropTypes.object,
  body: PropTypes.object,
  startButton: PropTypes.object,
  button: PropTypes.object
};

export default CreatePortfolioModal;
