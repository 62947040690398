import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import AppContext from 'context/Context';
import { Row, Col, Alert } from 'react-bootstrap';
import { trackEvent } from 'services/externals/mixpanel';
import { Banner as textLang } from 'staticData/languages';
import { are_agents_stopped } from 'staticData/unlockTimers';

const BannerConvertAgent = ({
  hasPortfolio = false,
  setOpenModal = () => null
}) => {
  const {
    config: { lang }
  } = useContext(AppContext);

  const handleclickBanner = () => {
    trackEvent('Banner convert Agent', { hasPorfolio: hasPortfolio });
    setOpenModal();
  };

  return (
    <>
      <Row className="pointerCursor" onClick={handleclickBanner}>
        <Col>
          <Alert variant="warning" className="mb-0">
            <Alert.Heading>
              {hasPortfolio
                ? textLang.titleConvert_portfolio[lang]
                : textLang.titleConvert[lang]}
            </Alert.Heading>
            <p
              dangerouslySetInnerHTML={{
                __html: hasPortfolio
                  ? are_agents_stopped
                    ? textLang.subTitle_portfolio_after[lang]
                    : textLang.subTitle_portfolio[lang]
                  : are_agents_stopped
                  ? textLang.subTitle_after[lang]
                  : textLang.subTitle[lang]
              }}
            />
          </Alert>
        </Col>
      </Row>
    </>
  );
};

BannerConvertAgent.propTypes = {
  hasPortfolio: PropTypes.bool,
  setOpenModal: PropTypes.func
};

export default BannerConvertAgent;
