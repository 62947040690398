import React, { useEffect, useContext } from 'react';
import { Button, Modal } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import tpLogo from 'assets/img/hodlie/trustpilot-logo.png';
import { Link } from 'react-router-dom';
import { trackEvent } from 'services/externals/mixpanel';
import AppContext from 'context/Context';
import PropTypes from 'prop-types';

const TrustPilotModal = ({ showModal, setShowModal }) => {
  const { userInfos } = useContext(AppContext);

  const handleClick = () => {
    trackEvent('Modal Trustpilot Click', {
      user_email: userInfos.email,
      tracking_id: userInfos.tracking_id
    });
  };

  const handleClose = () => {
    trackEvent('Modal Trustpilot Close', {
      user_email: userInfos.email,
      tracking_id: userInfos.tracking_id
    });
    setShowModal(false);
  };

  useEffect(() => {
    if (showModal) {
      trackEvent('Modal Trustpilot Show', {
        user_email: userInfos.email,
        tracking_id: userInfos.tracking_id
      });
    }
  }, [showModal]);

  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title id="contained-modal-title-vcenter">
          Facci sapere cosa ne pensi
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Siamo una startup italiana che ogni giorno cerca di semplificare il
          tuo accesso al mondo degli investimenti in criptovalute. Per
          continuare a farlo sempre meglio, abbiamo bisogno anche del tuo aiuto:
          ti va di raccontarci la tua esperienza? Un tuo feedback potrebbe fare
          una grande differenza!
        </p>
        <Flex className="justify-content-center mt-4">
          <Button
            style={{ backgroundColor: 'white' }}
            as={Link}
            to={{ pathname: 'https://it.trustpilot.com/review/hodlie.finance' }}
            target="_blank"
            onClick={handleClick}
          >
            <img
              className="me-2 mb-1"
              src={tpLogo}
              alt="trust-pilot-logo"
              height={40}
            />
          </Button>
        </Flex>
      </Modal.Body>
    </Modal>
  );
};

TrustPilotModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired
};

export default TrustPilotModal;
